@import 'theme/common-style';

.advantage {
  @include underline($color-gray-500, 0, -50svw, 200svw);
  @include container;

  display: grid;
  grid-template-columns: 0.9fr 1.1fr;

  grid-template-areas: 'image information';
  column-gap: 24px;
  max-width: 1206px;
  align-items: stretch;
  justify-items: center;
  column-gap: 60px;
  padding: 100px 0;

  &:nth-child(odd) {
    grid-template-areas: 'information image';
    grid-template-columns: 1.1fr 0.9fr;
  }

  @include screen-xs {
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'information';
    padding: 100px $side-container-padding-md-minus;
  }
}

.information {
  display: grid;
  align-self: center;
  padding: 100px 0;
  grid-area: information;
  font-size: 17px;
  line-height: 23px;
}

.image {
  display: grid;
  grid-area: image;
  width: 441px;
  height: auto;
  object-fit: contain;
}

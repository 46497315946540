@import 'theme/common-style';

.root {
  background-color: $color-primary;
  color: $color-neutral-100;
  position: relative;
  overflow-x: hidden;
  max-width: 100svw;
}

.background {
  width: 100%;
  height: 1160px;
  background-color: $color-primary;
  opacity: 25%;
  background-image: linear-gradient(180deg, $color-gray-700 0%, $color-primary 100%);

  position: absolute;
  top: 0;
  right: 0;
}

.content {
  opacity: 1;
  z-index: 2;
  overflow: visible;
  width: 100svw;
}

.advantage {
  @include underline($color-gray-500, 0, -50svw, 200svw);
  @include container;
  max-width: 1206px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'image information';
  align-items: stretch;
  justify-items: space-between;
  &:nth-child(odd) {
    grid-template-areas: 'information image';
  }

  @include screen-xs {
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'information';
    padding: 100px $side-container-padding-lg-minus 40px $side-container-padding-lg-minus;
  }
}

.workDuration {
  height: 100%;
  padding: 120px 13px;
  border-right: 1px solid $color-gray-500;
  padding-right: 100px;
  text-align: center;
}

.enlargedText {
  @include font(143, bold);
}

.batteryCapacityText {
  margin-top: 20px;
  font-size: 17px;
  line-height: 23px;
}

.carImage {
  display: grid;
  place-items: center;
  height: 40svw;
  max-height: 500px;
  object-fit: contain;
  transform: rotate(90deg) translate(30%, -50%);
}

.respiratorMask {
  @include underline($color-gray-500, 0, -50svw, 200svw);
  @include container;
  padding: 319px 20px;
  width: 100%;
  text-align: center;
  background-image: url('../../theme/assets/png/respirator-mask.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
}

.mask {
  @include underline($color-gray-500, 0, -50svw, 200svw);
  @include container;
  display: grid;
  grid-template-columns: repeat(2, fr);
  color: white;

  min-height: 774px;
  margin-top: 150px;
  padding: 150px 0 277px 0;
  background-image: url('../../theme/assets/png/man-in-a-medical-mask.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $color-primary;

  p {
    max-width: 330px;
    display: grid;
  }

  p:first-of-type {
    justify-self: end;
  }

  p:first-of-type + p {
    display: grid;
    align-self: end;
    text-align: right;
  }
}

@import 'theme/common-style';

@mixin btn-hover($color) {
  &:hover {
    background-color: darken($color, 10%);

    &:disabled {
      opacity: 0.5;
    }
  }
}

.root {
  @include font(15, medium);
  @include flex-container(center, center, row, true);
  position: relative;
  border: none;
  outline: none;
  white-space: nowrap;
  padding: 0 16px;
  color: $color-white;
  height: 34px;
  min-width: 72px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.primary {
  @include btn-hover($color-primary);
  color: $color-white;
  background-color: $color-primary;
  border: 1px solid $color-primary;
}

.secondary {
  @include btn-hover($color-white);
  color: $color-gray-100;
  background-color: $color-white;
  border: 1px solid $color-gray-200;
}

.loader {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@import 'theme/common-style';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include typography;
  @include loader-transition;
  min-height: 100vh;
  min-height: 100dvh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  opacity: 1;
  overflow-x: hidden;
  overscroll-behavior: none;
}

ul {
  list-style: none;
}

img,
picture,
video,
svg {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
  font: inherit;
}

button {
  cursor: pointer;
}

// ---------- Mixins ----------
.page {
  @include page;
}

.container {
  @include container;
}

.center {
  text-align: center;
}

.card {
  @include card;
}

.table {
  @include table;
}

.columnWithLimitedWidth {
  max-width: $max-table-column-width;
}

@import 'theme/common-style';

.loadingArea {
  @include flex-center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: $color-black-100;
}

@import 'src/theme/common-style.scss';

$navbar-height: 89px;

.root {
  width: 100svw;
  background-color: $color-gray-800;
  overflow: hidden;
  color: $color-neutral-100;
}

.navbar {
  height: $navbar-height;
  @include flex-container(space-between, center, row, $gap: 60px);
  @include container;
}

.navbarButtonsList {
  @include flex-container(stretch, flex-end, $gap: 60px);
}

.navbarButton {
  background-color: transparent;
  border: none;
  height: $navbar-height;
  @include font(12, $font-w-medium);

  &:hover {
    background-color: darken($color-gray-800, 3%);
  }
}

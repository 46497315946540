@import 'theme/common-style';

.root {
  width: 100svw;
  overflow-x: hidden;
  background-color: $color-black-100;
  color: $color-neutral-100;

  @include screen-sm {
    max-height: 100svh;
  }
}

.heroContainer {
  @include container;
  background-color: $color-black-100;
  position: relative;

  @include flex-center;
  padding-bottom: 32px;

  @include screen-md-plus {
    min-height: 980px;
  }

  @include screen-md-minus {
    min-height: 561px;
  }

  @include screen-xs {
    min-height: 261px;
    background-size: 80%;
  }
}

.bgGradient {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: radial-gradient(
    $color-gray-semitransparent 0%,
    $color-gray-transparent 30%,
    $color-black-100 70%,
    $color-black-100 100%
  );
}

.bgImage {
  position: absolute;
  object-fit: contain;
  z-index: 3;
}

.title {
  z-index: 4;
}

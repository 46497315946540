$color-primary: #181a1d;

$color-neutral-100: #ffffff;
$color-neutral-200: #fafafa;

$color-gray-100: #f8fbfd;
$color-gray-200: #e1e3e8;
$color-gray-300: #959595;
$color-gray-400: #999999;
$color-gray-500: #4e4e4e;
$color-gray-600: #494f56;
$color-gray-700: #3e454b;
$color-gray-800: #393a3c;
$color-gray-900: #232323;

$color-gray-semitransparent: rgba(108, 108, 108, 0.52);
$color-gray-more-semitransparent: rgba(108, 108, 108, 0.72);
$color-gray-transparent: rgba(108, 108, 108, 0.15);

$color-white: $color-neutral-100;

$color-black-100: #18181b;
$color-black-200: #12141d;
$color-black-300: #000000;

$color-black-semitransparent: rgba(0, 0, 0, 0.07);
$color-black-transparent: rgba(24, 26, 29, 0.2);

$color-red-100: #ff0000;
$color-red-semitransparent: #ff00002b;
$color-red-transparent: #ff00000a;

$color-error: $color-red-100;

$color-gray-border: $color-gray-300;

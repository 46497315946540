@import 'src/theme/common-style.scss';

.root {
  width: 100svw;
  background-color: $color-primary;
  overflow: hidden;
  color: $color-neutral-100;
  @include screen-max(1480) {
    padding: 0 20px;
  }
}

.footer {
  position: relative;
  @include container;
  padding-top: 120px;
  @include flex-container(space-between, flex-end, $gap: 60px);
  overflow: hidden;
  color: $color-neutral-100;
}

.footerSlogan {
  position: relative;
  bottom: -20px;
  @include font(143, $font-w-bold);
  line-height: 143px;
}

.arrow {
  position: relative;
  bottom: 80px;
}

@import 'theme/common-style';

.root {
  position: relative;
  flex: 1;
}

.label {
  @include font(12, bold);
}

.input {
  @include font(14);
  height: inherit;
  width: 100%;
  background-color: transparent;
  padding-right: 10px;
  color: $color-white;
  border: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &Error {
    border-color: $color-error;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }

  &Container {
    @include flex-container($align-items: flex-start, $flex-flow: column);
    height: 23.5px;

    > div {
      @include flex-container;
      position: relative;
      height: 23.5px;
      width: 100%;

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 2px;
        background-color: $color-white;
        top: 100%;
        left: 10px;
      }
    }

    > span {
      display: inline-block;
      padding-top: 1px;
    }
  }
}

.hasError {
  height: 50px;
}

.error {
  @include font(12, medium);
  color: $color-error;
  position: absolute;
  bottom: 0px;
}

.loader {
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
}

@import 'theme/common-style';

.root {
  // overflow: hidden;
  // max-width: 100svw;
  background-color: $color-neutral-100;
  color: $color-gray-900;
  padding: 130px 0 118px 0;
  width: 100%;
  overflow-x: auto;
}

.tableHeaderCell {
  @include flex-container(center, center, column);
  padding-bottom: 79px;
}

.filterName {
  word-spacing: 100vw;
}

.carImage {
  display: grid;
  place-items: center;
  height: 220px;
  object-fit: contain;
  transform: rotate(90deg);
}

.orderButton {
  padding: 10px 45px;
  background-color: $color-black-semitransparent;
  color: $color-black-300;
  border: none;
  @include font(15, bold);

  &:hover {
    background-color: darken($color-gray-200, 3%);
  }
}

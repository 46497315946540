$table-sides-padding: 20px;
$large-table-sides-padding: 60px;
$xl-table-sides-padding: 100px;
$max-table-column-width: 323px;

@mixin table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: center;
    padding: 12px 12px 8px 12px;
    color: $color-gray-900;
  }

  tr th:last-child {
    padding-right: $table-sides-padding;

    @include screen-min($screen-hg-max) {
      padding-right: $large-table-sides-padding;
    }

    @include screen-min($screen-xl-min) {
      padding-right: $xl-table-sides-padding;
    }
  }

  tr th:first-child {
    padding-left: $table-sides-padding;

    @include screen-min($screen-hg-max) {
      padding-left: $large-table-sides-padding;
    }

    @include screen-min($screen-xl-min) {
      padding-left: $xl-table-sides-padding;
    }
  }

  td {
    @include font(17, $font-w-semibold);
  }

  th {
    @include font(13, $font-w-bold);
  }

  tbody {
    tr td:nth-child(2) {
      @include font(17, $font-w-regular);
      text-align: right;
      padding-left: $table-sides-padding;

      @include screen-md-minus {
        white-space: break-spaces;
      }

      @include screen-min($screen-hg-max) {
        padding-left: $large-table-sides-padding;
      }

      @include screen-min($screen-xl-min) {
        padding-left: $xl-table-sides-padding;
      }
    }

    tr td:last-child {
      padding-right: $table-sides-padding;

      @include screen-min($screen-hg-max) {
        padding-right: $large-table-sides-padding;
      }

      @include screen-min($screen-xl-min) {
        padding-right: $xl-table-sides-padding;
      }
    }

    tr:nth-child(odd) {
      th,
      td {
        background-color: $color-black-semitransparent;
      }
    }

    tr:nth-child(even) {
      th,
      td {
        background-color: $color-neutral-100;
      }
    }
  }
}

@import 'theme/common-style';

.root {
  @include flex-center;
  overflow: hidden;
  background-image: url('../../theme/assets/png/bg-login.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  height: 100svh;
  width: 100svw;
  background-color: $color-gray-500;
  position: relative;
}

.logo {
  position: absolute;
  top: 65px;
  left: 65px;
}

.form {
  position: relative;
  color: $color-neutral-100;
  width: 296px;
  border-radius: 4px;
  overflow: hidden;
  background-color: transparent;

  @include screen-xs {
    width: 90%;
  }
}

.blurBackground {
  background-color: $color-gray-semitransparent;
  backdrop-filter: blur(10px);
  position: absolute;
  width: 100%;
  height: 100%;
}

.content {
  @include flex-container($align-items: flex-end, $gap: 12px);
  padding: 5px 16px 12.5px 16px;
  width: 100%;
  height: 100%;
  z-index: 4;
  border-radius: 4px;
  filter: blur(0);
}

.hasError {
  align-items: center;
}

.submitBtn {
  background-color: transparent;
  border: none;

  &Disabled {
    pointer-events: none;
  }
}

.toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: $color-error;
  padding: 15px 60px 15px 20px;
  border-radius: 5px;
  color: $color-neutral-100;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    path {
      fill: $color-neutral-100;
    }
  }
}

@import 'theme/common-style';

.root {
  width: 100svw;
  @include flex-container(flex-start, center);
  overflow-x: hidden;
  background-color: $color-black-100;
  color: $color-neutral-100;
  padding-bottom: 200px;
}

.filterContainer {
  @include container;
  @include flex-container(flex-start, center, column, 60px);
  padding-bottom: 40px;

  @include screen-xs {
    gap: 30px;
  }

  @include screen-lg {
    max-width: 100svw;
    padding: 0 $side-container-padding-lg-minus 40px $side-container-padding-lg-minus;
  }
}

.information {
  max-width: 900px;
  font-weight: $font-w-bold;
  line-height: 125%;

  @include screen-xs {
    text-align: center;
  }
}

.description {
  color: $color-gray-600;
}

.slogan {
  margin: 210px 0 46px 0;
  max-width: 800px;
  text-align: center;

  @include screen-xs {
    margin-top: 60px;
  }
}

.wavelength {
  width: 100%;
  display: flex;
  align-self: stretch;
  justify-self: stretch;

  position: relative;
  padding-bottom: 50px;

  @include screen-xs {
    padding-bottom: 8px;
  }
}

.wavelengthRange {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.deviceDescription {
  padding-top: 18px;
  text-align: center;
  max-width: 800px;
  letter-spacing: -0.1px;
  line-height: 23px;
  @include font(17, regular);
}

.filterConstruction {
  padding-top: 205px;
  display: grid;
  max-width: 800px;
  grid-template-columns: 40% 50%;
  gap: 10%;
  align-items: center;
  justify-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: radial-gradient($color-gray-transparent 0%, $color-black-transparent 50%, $color-black-100 100%);

  @include screen-xs {
    padding-top: 60px;
    grid-template-columns: 100%;
    gap: 40px;
  }
}

.toyCar {
  object-fit: contain;
}

.filterDescription {
  @include font(22, medium);
  letter-spacing: -0.1px;
  padding-right: 10px;
}

@import './common-colors.scss';
@import './common-flex.scss';
@import './common-font.scss';
@import './common-responsive.scss';
@import './common-scrollbar.scss';
@import './common-table.scss';

$max-width-dimension: 1024px;
$side-container-padding: 65px;
$side-container-padding-lg-minus: 35px;
$side-container-padding-md-minus: 16px;

@mixin page {
  height: 100%;
  position: relative;
}

@mixin card($padding: 24px) {
  padding: $padding;
  background-color: $color-white;
}

@mixin container {
  margin: 0 auto;
  max-width: 1500px;
  padding: 0 30px;

  @include screen-xs {
    max-width: 90%;
    padding: 0 20px;
  }
}

@mixin loader-transition {
  .loader-transition {
    opacity: 0;
  }

  .loader-transition-enter {
    opacity: 0;
  }

  .loader-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }

  .loader-transition-exit {
    opacity: 1;
  }

  .loader-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
}

@mixin underline($bg-color: inherit, $bottom: '0', $right: '0', $width: 100%, $height: 1px) {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: $bottom;
    left: $right;
    width: $width;
    height: $height;
    background-color: $bg-color;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

$font-family-primary: Montserrat, Helvetica Neue, Arial;

$fonts: (
  '': 16px,
  '8': 8px,
  '10': 10px,
  '11': 11px,
  '12': 12px,
  '13': 13px,
  '14': 14px,
  '15': 15px,
  '16': 16px,
  '17': 17px,
  '18': 18px,
  '19': 19px,
  '20': 20px,
  '22': 22px,
  '23': 23px,
  '24': 24px,
  '26': 26px,
  '28': 28px,
  '30': 30px,
  '31': 31px,
  '32': 32px,
  '36': 36px,
  '38': 38px,
  '40': 40px,
  '44': 44px,
  '45': 45px,
  '54': 54px,
  '58': 58px,
  '73': 73px,
  '100': 100px,
  '143': 143px,
);

$font-w-regular: 400;
$font-w-medium: 500;
$font-w-semibold: 600;
$font-w-bold: 700;

@mixin font($font, $weight: $font-w-regular, $height: normal) {
  font-size: map-get($fonts, '#{$font}');
  line-height: $height;

  @if $weight == bold {
    font-weight: $font-w-bold;
  } @else if $weight == semibold {
    font-weight: $font-w-semibold;
  } @else if $weight == medium {
    font-weight: $font-w-medium;
  } @else if $weight == regular {
    font-weight: $font-w-regular;
  } @else {
    font-weight: $weight;
  }
}

@mixin typography {
  * {
    font-family: $font-family-primary;
  }

  h1 {
    @include font(143, bold);
    line-height: 120%;
    letter-spacing: 0;

    @include screen-md-minus {
      font-size: 100px;
    }

    @include screen-xs {
      font-size: 54px;
    }
  }

  h2 {
    @include font(58, bold);
    line-height: 120%;
    letter-spacing: -0.3px;

    @include screen-xs {
      font-size: 30px;
    }
  }

  h3 {
    @include font(38, bold);
    line-height: 120%;
    letter-spacing: 0.1px;

    @include screen-xs {
      font-size: 26px;
    }
  }

  h4 {
    @include font(31, bold);
    line-height: 120%;
  }

  p {
    @include font(22, regular);
    line-height: 120%;
  }

  a {
    text-decoration: none;
  }
}

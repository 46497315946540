@mixin flex-container(
  $justify-content: 'flex-start',
  $align-items: 'stretch',
  $flex-flow: 'row nowrap',
  $inline: false,
  $gap: normal
) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  justify-content: #{$justify-content};
  align-items: #{$align-items};
  flex-flow: #{$flex-flow};
  gap: #{$gap};
  @content;
}

@mixin flex-center {
  @include flex-container(center, center);
}
